import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

//components
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Features from './components/Features';
import Contact from './components/Contact';
import Security from './components/Security';
import ChangeLog from './components/ChangeLog';

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <Features />
              <Contact />
            </>
          } />
          <Route path="/security" element={<Security />} />
          <Route path="/changelog" element={<ChangeLog />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;