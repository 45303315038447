import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//assets
import XLogo from "../assets/x.svg";
import LinkedInLogo from "../assets/linkedin.svg";
import Terms from "../assets/ArcexTermsOfService.pdf";
import Privacy from "../assets/ArcexPrivacyPolicy.pdf";

export default function Contact() {

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    phoneNumber: "",
    message: "",
  });

  useEffect(() => {
    setHasSubmitted(false);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("data", formData)

    try {
      const response = await fetch("https://landing-be-production.up.railway.app/get-in-touch/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      console.log("Form submitted successfully");
      setHasSubmitted(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-[95vh] bg-primary-200 px-4 pt-12 pb-6 sm:px-[5%] lg:px-[10%] w-full">
      <div className='flex flex-col justify-center sm:flex-row gap-4 w-full'> 
        <div className="grow flex justify-center items-center border-2 border-primary-100 max-w-[700px] w-full sm:w-auto bg-white rounded-lg sm:rounded-xl shadow-xl py-6 px-8">
          <div className={`${hasSubmitted ? 'visible' : 'hidden'}`}>
            <h2>Thank you. Your message has been sent.</h2>
          </div>
          <div className={`${hasSubmitted ? 'hidden' : 'visible'}`}>
            <h1>Get in touch</h1>
            <form className="flex flex-col" onSubmit={handleSubmit}>
              <div className='flex items-center justify-between gap-2'>
                <div className='flex flex-col grow'>
                  <label htmlFor="firstName" className="mt-2 text-gray-700">First Name*</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="mt-1 w-full py-2 px-3 rounded-md border-2 border-primary-100 shadow-md focus:border-primary-100 focus:ring-primary-100 focus:outline-none"
                    placeholder="First Name"
                    onChange={handleChange}
                    required
                    autoComplete='on'
                  />
                </div>
                <div className='flex flex-col grow'>
                  <label htmlFor="lastName" className="mt-2 text-gray-700">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="mt-1 w-full py-2 px-3 rounded-md border-2 border-primary-100 shadow-md focus:border-primary-100 focus:ring-primary-100 focus:outline-none"
                    placeholder="Last Name"
                    onChange={handleChange}
                    autoComplete='on'
                  />
                </div>
              </div>
              <label htmlFor="email" className="mt-2 text-gray-700">Email*</label>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-1 w-full py-2 px-3 rounded-md border-2 border-primary-100 shadow-md focus:border-primary-100 focus:ring-primary-100 focus:outline-none"
                placeholder="your@email.com"
                onChange={handleChange}
                required
                autoComplete='on'
              />
              <div className='flex items-center justify-between gap-2'>
                <div className='flex flex-col grow'>
                  <label htmlFor="companyName" className="mt-2 text-gray-700">Company</label>
                  <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    className="mt-1 w-full py-2 px-3 rounded-md border-2 border-primary-100 shadow-md focus:border-primary-100 focus:ring-primary-100 focus:outline-none"
                    placeholder="Company"
                    onChange={handleChange}
                    autoComplete='on'
                  />
                </div>
                <div className='flex flex-col grow'>
                  <label htmlFor="phoneNumber" className="mt-2 text-gray-700">Phone</label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    className="mt-1 w-full py-2 px-3 rounded-md border-2 border-primary-100 shadow-md focus:border-primary-100 focus:ring-primary-100 focus:outline-none"
                    placeholder="123-456-7890"
                    onChange={handleChange}
                    autoComplete='on'
                  />
                </div>
              </div>
              <label htmlFor="message" className="mt-2 text-gray-700">Message*</label>
              <textarea
                id="message"
                name="message"
                rows="4"
                className="mt-1 w-full py-2 px-3 rounded-md border-2 border-primary-100 shadow-md focus:border-primary-100 focus:ring-primary-100 focus:outline-none"
                placeholder="Your message here..."
                onChange={handleChange}
                required
              ></textarea>

              <button type="submit" className="inline-flex justify-center mt-4 py-2 border-2 border-primary-100 shadow-md rounded-md text-primary-100 bg-primary-200 hover:bg-white transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-100">
                <h2>Send Message</h2>
              </button>
            </form>
          </div>
        </div>

      <div className="flex flex-col gap-4 w-full sm:w-3/5 sm:max-w-[400px]">
        
        <div className="flex grow justify-between items-center border-2 border-primary-100 w-full bg-white rounded-lg sm:rounded-xl shadow-xl py-6 px-8">
          <div>
            <a className='cursor-pointer' href='https://docsend.com/view/s/x2bsv5fsvx3fdsje' target='_blank' rel="noreferrer">
              <h2 className="text-xl font-medium cursor-pointer">Investors</h2>
            </a>
            <a className='cursor-pointer' href='https://www.linkedin.com/company/arcexapp/people/' target='_blank' rel="noreferrer">
              <h2 className="text-xl font-medium">Meet the Team</h2>
            </a>
            <a className='cursor-pointer' href={Terms} target='_blank' rel='noopener noreferrer'>
            <h2 className="text-xl font-medium cursor-pointer">Terms of Service</h2>
            </a>
            <a className='cursor-pointer' href={Privacy} target='_blank' rel='noopener noreferrer'>
            <h2 className="text-xl font-medium cursor-pointer">Privacy Policy</h2>
            </a>
            <Link to="/security" className='cursor-pointer'>
              <h2 className="text-xl font-medium cursor-pointer">Security</h2>
            </Link>
            <Link to="/changelog" className='cursor-pointer'>
              <h2 className="text-xl font-medium cursor-pointer">Change Log</h2>
            </Link>
            <div className="flex flex-col gap-2" onClick={() => window.open('https://status.arcex.app', '_blank')}>
              <h2 className="text-xl font-medium cursor-pointer">Status</h2>
            </div>
          </div>
        </div>

        <div className="flex justify-center grow items-center gap-16 border-2 border-primary-100 w-full bg-white rounded-lg sm:rounded-xl shadow-xl py-6 px-8">
          <a className="cursor-pointer block" href='https://x.com/ArcexApp' target='_blank' rel="noreferrer">
            <img src={XLogo} alt="X" className="size-[60px] sm:size-[80px]" />
          </a>
          <a className="cursor-pointer block" href='https://www.linkedin.com/company/arcexapp' target='_blank' rel="noreferrer" >
            <img src={LinkedInLogo} alt="LinkedIn" className="size-[60px] sm:size-[80px]" />
          </a>
        </div>

        <div className="flex justify-center items-center border-2 border-primary-100 w-full bg-white rounded-lg sm:rounded-xl shadow-xl py-6 px-8">
            <h3>&copy; {new Date().getFullYear()} Arcex, Inc. All rights reserved.</h3>
        </div>

      </div>

      </div>
    </div>
  );
}
